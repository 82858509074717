<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2
      .card
        .card-body.col-lg-12
          .header-row
            .form-group.col-lg-9.pl-3.row.mb-3
              .form-group.col-lg-5.pl-0.mb-0
                input.form-control(type="text", placeholder="Search...")
              .pl-2.mt-2.mb-0
                b-form-checkbox#checkbox-1(v-model="status", name="checkbox", value="accepted", unchecked-value="not_accepted") 總統大選
              .pl-2.mt-2.mb-0
                b-form-checkbox#checkbox-1(v-model="status", name="checkbox", value="accepted", unchecked-value="not_accepted") 九合一選舉
          //- Table
          .table-responsive.mb-0
            b-table(:items="tableData", :fields="fields", responsive="sm", :per-page="perPage", :current-page="currentPage", :sort-desc.sync="sortDesc", :filter="filter", :filter-included-fields="filterOn", @filtered="onFiltered")
          .row.mb-md-2
            //- .col-6
            //-   div(id="tickets-table_length", class="dataTables_length")
            //-     label.d-inline-flex.align-items-center
            //-       | Show
            //-       b-form-select.ml-1.mr-1(v-model="perPage", size="sm", :options="pageOptions")
            //-       | entries
            .col-12.row.d-flex.justify-content-end  
              div(class="dataTables_paginate paging_simple_numbers float-right")
                ul.pagination.pagination-rounded.mb-0
                  // pagination
                  b-pagination(v-model="currentPage", :total-rows="rows", :per-page="perPage")
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "候選人帳號設定",
      items: [
        {
          text: "選舉頁面專區",
          href: "/election",
        },
        {
          text: "候選人帳號設定",
          active: true,
        }
      ],
      search_data: {
        status: 0,
      },
      value: '',
      daterange: '',
      visible: true,

      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "＃",
          sortable: true,
        },
        {
          key: "候選人姓名",
          sortable: true,
        },
        {
          key: "綁定狀態",
          sortable: false,
        },
        {
          key: "最後更新時間",
          sortable: false,
        },
        {
          key: "操作",
          sortable: false,
        },
      ],

      tab: 1
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows () {
      return this.tableData.length;
    },
  },
  mounted () {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
  },
  methods: {
    changeTab (_status) {
      this.tab = _status
    }
  }
};
</script>

<style lang="sass" scoped>
.header-row
  display: flex
  justify-content: space-between
  align-items: center
</style>
